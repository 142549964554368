import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import '../styles/listaFaktura.css'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer, {tableContainerClasses} from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getUserInvoices, getCurrentTimeRangeKey } from '../utils/utils.js'
import { Typography } from '@mui/material'
const Loading = styled.section``

const StyledTableContainer = styled(TableContainer)(() => ({
    [`&.${tableContainerClasses.root}`]: {
      padding: 0,
    },
  }));

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#495057',
      color: 'white',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#dee2e6',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export const ListaFaktura = ({userApiStatus}) => {
    const [month, setMonth] = useState('012025')
    const [dataResult, setDataResult] = useState()
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [fakture, setFaktureRows] = useState([])
    const [loadingNewInvoiceTimePeriod, setInvoiceLoading] = useState(true)

    const handleChange = (event) => {
      setFaktureRows([])
      setInvoiceLoading(true)
      let odabraniMesec = event.target.value
      setMonth(odabraniMesec)
      console.log('Odabrani mesec', odabraniMesec)
      getUserInvoices(odabraniMesec).then((result)=>{
        //setDataResult(result)
        console.log(result)
        generateInvoiceRows(result)
      })
    };

    let currentTimeRanege = getCurrentTimeRangeKey()
    console.log('Trenutni time range', currentTimeRanege)
    if(!isDataLoaded) {
      //setMonth(currentTimeRanege)
      getUserInvoices(currentTimeRanege).then((result)=>{
        console.log('Fakture', result)
        //setDataResult(result)
        setInvoiceLoading(true)
        generateInvoiceRows(result)
        setIsDataLoaded(true)
      })
    }

    const generateInvoiceRows = (fakture) => {
      let invoiceRows = []
      if (fakture.length > 0) {
        for(let i = 0; i < fakture.length; i++) {
          let invoice = fakture[i]
          invoiceRows.push(
            <StyledTableRow
                key={invoice.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <StyledTableCell>{invoice.label} - {invoice.broj_fakture}</StyledTableCell>
            <StyledTableCell>{invoice.buyer.naziv}</StyledTableCell>
            <StyledTableCell>{invoice.ukupno_za_placanje} {invoice.currency_code}</StyledTableCell>
            <StyledTableCell>{invoice.invoice_already_in_sef === false ? "Nije poslat u SEF" : "Dokument poslat u SEF ID dokumenta " + invoice.invoice_already_in_sef}</StyledTableCell>
            <StyledTableCell><Link to={'/dokument/' + invoice.id }>{invoice.invoice_already_in_sef === false ? "Spremi dokument za slanje u SEF" : "Detalji"}</Link></StyledTableCell>
            </StyledTableRow>
          )
        }
      } else {
        invoiceRows.push(
          <StyledTableRow
              key={1}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
          <StyledTableCell>Za odabrani period nema dokumenata</StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        )
      }
      setFaktureRows(invoiceRows)
      setInvoiceLoading(false)
    }
    
    
    return (
        <>
            {(userApiStatus && isDataLoaded) && 
            <Box className="row documentHead">
                <Typography variant="h4" marginBottom="30px">Dokumenti</Typography>
                <Box display="flex" alignItems="center" gap="15px" marginBottom="20px" >
                    <Typography>Odaberite vremenski period</Typography>
                    <FormControl sx={{ width: 200}} size="small">
                        <InputLabel>Meseci</InputLabel>
                            <Select
                                value={month}
                                label="Meseci"
                                onChange={handleChange}
                            >
                            <MenuItem value={'122022'} key={1}>Decembar 2022</MenuItem>
                            <MenuItem value={'012023'} key={12}>Januar 2023</MenuItem>
                            <MenuItem value={'022023'} key={13}>Februar 2023</MenuItem>
                            <MenuItem value={'032023'} key={14}>Mart 2023</MenuItem>
                            <MenuItem value={'042023'} key={15}>April 2023</MenuItem>
                            <MenuItem value={'052023'} key={16}>Maj 2023</MenuItem>
                            <MenuItem value={'062023'} key={17}>Jun 2023</MenuItem>
                            <MenuItem value={'072023'} key={18}>Jul 2023</MenuItem>
                            <MenuItem value={'082023'} key={19}>Avgust 2023</MenuItem>
                            <MenuItem value={'092023'} key={111}>Septembar 2023</MenuItem>
                            <MenuItem value={'102023'} key={112}>Oktobar 2023</MenuItem>
                            <MenuItem value={'112023'} key={113}>Novembar 2023</MenuItem>
                            <MenuItem value={'122023'} key={114}>Decembar 2023</MenuItem>
                            <MenuItem value={'012024'} key={115}>Januar 2024</MenuItem>
                            <MenuItem value={'022024'} key={116}>Februar 2024</MenuItem>
                            <MenuItem value={'032024'} key={117}>Mart 2024</MenuItem>
                            <MenuItem value={'042024'} key={118}>April 2024</MenuItem>
                            <MenuItem value={'052024'} key={119}>Maj 2024</MenuItem>
                            <MenuItem value={'062024'} key={120}>Jun 2024</MenuItem>
                            <MenuItem value={'072024'} key={121}>Jul 2024</MenuItem>
                            <MenuItem value={'082024'} key={122}>Avgust 2024</MenuItem>
                            <MenuItem value={'092024'} key={123}>Septembar 2024</MenuItem>
                            <MenuItem value={'102024'} key={124}>Oktobar 2024</MenuItem>
                            <MenuItem value={'112024'} key={125}>Novembar 2024</MenuItem>
                            <MenuItem value={'122024'} key={126}>Decembar 2024</MenuItem>
                            <MenuItem value={'012025'} key={127}>Januar 2025</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
           
            <StyledTableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <StyledTableRow>
                    <StyledTableCell>Ime</StyledTableCell>
                    <StyledTableCell>Izdaje</StyledTableCell>
                    <StyledTableCell>Iznos</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Link</StyledTableCell>
                </StyledTableRow>
                </TableHead>
                <TableBody>
                { fakture }
                </TableBody>
            </Table>
            </StyledTableContainer>
            </Box>} 
            {loadingNewInvoiceTimePeriod && <Loading className='card mb-4'><div className="card-body">Učitavanje faktura</div></Loading>} 
        </>
    )
}

ListaFaktura.propTypes = {
    userApiStatus: PropTypes.bool
}